import { type JSX, type ReactNode, useId } from "react";

import { tooltipAnatomy } from "@ark-ui/anatomy";
import { Tooltip as ArkTooltip, type TooltipRootProps } from "@ark-ui/react";
import {
  sva,
  cx,
  type RecipeVariantProps,
} from "@mobsuccess-devops/styled-system/css";
import type { Placement } from "@zag-js/tooltip";

import { ZFactor } from "../../ZFactor";

export type TooltipProps = {
  id?: string;
  className?: string;
  disabled?: boolean;
  content: ReactNode;
  children: JSX.Element;
  placement?: Placement;
  contentClassName?: string;
} & RecipeVariantProps<typeof styles> &
  Omit<TooltipRootProps, "positioning">;
export function Tooltip({
  id,
  variant,
  content,
  children,
  disabled,
  className,
  openDelay = 0,
  closeDelay = 0,
  contentClassName,
  placement = "top",
  ...rest
}: TooltipProps): JSX.Element {
  const positioning = {
    placement: placement,
    gutter: 8,
  };
  const fallbackId = useId();

  const classes = styles({ variant });
  return (
    <ArkTooltip.Root
      id={id ?? fallbackId}
      disabled={disabled}
      openDelay={openDelay}
      closeDelay={closeDelay}
      positioning={positioning}
      {...rest}
    >
      <ArkTooltip.Trigger asChild className={cx(classes.trigger, className)}>
        {children}
      </ArkTooltip.Trigger>
      <ZFactor.Portal>
        <ArkTooltip.Positioner className={classes.positioner}>
          <ArkTooltip.Content className={cx(classes.content, contentClassName)}>
            <ArkTooltip.Arrow className={classes.arrow}>
              <ArkTooltip.ArrowTip className={classes.arrowTip} />
            </ArkTooltip.Arrow>
            {content}
          </ArkTooltip.Content>
        </ArkTooltip.Positioner>
      </ZFactor.Portal>
    </ArkTooltip.Root>
  );
}

const styles = sva({
  slots: tooltipAnatomy.keys(),
  base: {
    positioner: {
      zIndex: "10000!",
    },
    content: {
      padding: "sm",
      borderRadius: "sm",
      fontSize: "sm",
    },
    arrow: {
      "--arrow-size": "8px",
    },
  },
  variants: {
    variant: {
      dark: {
        content: {
          color: "white",
          backgroundColor: "interface.600",
        },
        arrow: {
          "--arrow-background":
            "linear-gradient(135deg, token(colors.interface.600) 50%, transparent 50%)",
        },
        arrowTip: {
          background: "interface",
        },
      },
      light: {
        arrow: {
          "--arrow-background":
            "linear-gradient(135deg, token(colors.interface.100) 50%, transparent 50%)",
        },
        content: {
          color: "interface",
          backgroundColor: "interface.100",
        },
      },
    },
  },
  defaultVariants: {
    variant: "dark",
  },
});
