import { type JSX, type ReactNode, useMemo } from "react";

import {
  Combobox,
  type ComboboxRootProps,
  createListCollection,
  type UseComboboxContext,
} from "@ark-ui/react";

import {
  SelectItemStart,
  SelectItemEnd,
  SelectIcon,
  SelectTrigger,
  SelectAddonTrigger,
  defaultFilter,
} from "./Decorators";
import { SelectMultiple } from "./SelectMultiple";
import { SelectSingle } from "./SelectSingle";
import { isInFilteringState } from "./isInFilteringState";
import { useSelectItems } from "./utils";

export type SelectValueChangeDetails<T extends SelectItem = SelectItem> = {
  value: Array<T["value"]>;
  items: T[];
};

export type SelectItemGroup<T extends SelectItem = SelectItem> = {
  type: "group";
  id: string;
  label: string;
  items: T[];
};

export type SelectItem<T extends string = string> = {
  type?: "item";
  label: string;
  value: T;
  disabled?: boolean;
  decorators?: ReactNode;
};

export type SelectItems<T extends SelectItem = SelectItem> = (
  | T
  | SelectItemGroup<T>
)[];

export type BaseSelectProps<T extends SelectItem> = Omit<
  ComboboxRootProps<T>,
  "asChild" | "onValueChange"
> & {
  items: SelectItems<T>;
  isSearchable?: boolean;
  limitTags?: number;
  width?: string;
  isClearable?: boolean;
  filterFunction?: (
    item: SelectItems<T>[number],
    search: string,
    group?: SelectItemGroup<T>,
  ) => boolean;
  size?: "sm" | "md" | "lg" | "xl";
  onValueChange?: (details: SelectValueChangeDetails<T>) => void;
  // TODO: FTTECH-79 - Make type for input state

  state?: "error" | "success" | "warning";
};

export type SelectRootProps<T extends SelectItem = SelectItem> = Omit<
  BaseSelectProps<T>,
  "collection"
>;

export function useIsInFilteringState(
  api: UseComboboxContext<SelectItem>,
  isSearchable?: boolean,
): boolean {
  return useMemo(() => {
    if (!isSearchable) {
      return false;
    }

    return isInFilteringState({
      isSearchable,
      inputValue: api.inputValue,
      selectedItems: api.selectedItems.filter((item) => !!item),
    });
  }, [api, isSearchable]);
}

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables, @mobsuccess-devops/mobsuccess/variables
function SelectRoot<ValueType extends string = string>({
  items,
  multiple,
  isSearchable = true,
  isClearable = true,
  filterFunction = defaultFilter,
  ...props
}: SelectRootProps<SelectItem<ValueType>>): JSX.Element {
  const collection = useMemo(() => {
    return createListCollection({
      items: items.flatMap((item) => {
        if (item.type === "group") {
          return item.items.map(({ decorators, ...rest }) => rest);
        }
        const { decorators, ...rest } = item;
        return rest;
      }),
      itemToValue: (item) => item.value,
      itemToString: (item) => item.label,
      isItemDisabled: (item) => item.disabled ?? false,
    });
  }, [items]);
  if (multiple) {
    return (
      <SelectMultiple
        items={items}
        collection={collection}
        isClearable={isClearable}
        isSearchable={isSearchable}
        filterFunction={filterFunction}
        {...props}
      />
    );
  }
  return (
    <SelectSingle
      items={items}
      collection={collection}
      isSearchable={isSearchable}
      isClearable={isClearable}
      filterFunction={filterFunction}
      {...props}
    />
  );
}

export const Select = {
  Root: SelectRoot,
  Icon: SelectIcon,
  ItemStart: SelectItemStart,
  ItemEnd: SelectItemEnd,
  Trigger: SelectTrigger,
  Context: Combobox.Context,
  AddonTrigger: SelectAddonTrigger,
  useItems: useSelectItems,
};
