import {
  type JSX,
  type ReactNode,
  forwardRef,
  type ForwardedRef,
  type ComponentPropsWithoutRef,
  useCallback,
  type ChangeEventHandler,
} from "react";

import { cx } from "@mobsuccess-devops/styled-system/css";
import { styled } from "@mobsuccess-devops/styled-system/jsx";

import {
  inputStyles,
  type InputVariants,
} from "../../../features/shared-styles/input";
import {
  textFieldDecorationStyles,
  type TextFieldDecorationVariants,
} from "../../../features/shared-styles/text-fields";
import {
  InputLeadingAddon,
  InputPrefix,
  InputSuffix,
  InputTrailingAddon,
} from "../AbstractInput/Decorators";

export type InputValueChangeDetails = {
  value: string;
};

type BaseInputProps = {
  children?: ReactNode;
  containerRef?: ForwardedRef<HTMLDivElement>;
  onValueChange?: (details: InputValueChangeDetails) => void;
} & Omit<ComponentPropsWithoutRef<"input">, "size">;

type Variants = NonNullable<InputVariants> & TextFieldDecorationVariants;

export type InputRootProps = Variants & Omit<BaseInputProps, keyof Variants>;

// eslint-disable-next-line @mobsuccess-devops/mobsuccess/variables
function InputRoot(
  { containerRef, children, onChange, onValueChange, ...rest }: InputRootProps,
  ref: ForwardedRef<HTMLInputElement>,
): JSX.Element {
  const [variants, restProps] = inputStyles.splitVariantProps(rest);
  const [decorationVariants, inputProps] =
    textFieldDecorationStyles.splitVariantProps(restProps);

  const classes = inputStyles(variants);
  const decoration = textFieldDecorationStyles(decorationVariants);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      onValueChange?.({ value: event.target.value });
      onChange?.(event);
    },
    [onChange, onValueChange],
  );

  return (
    <div
      ref={containerRef}
      data-scope="input"
      data-role="root"
      data-disabled={inputProps.disabled || undefined}
      className={cx(classes.root, inputProps.className, decoration, "group")}
    >
      {children}
      <styled.input
        order={2}
        placeholder=""
        width="100%"
        onChange={handleChange}
        {...inputProps}
        ref={ref}
        className={classes.input}
        data-validity-status={decorationVariants.state}
      />
    </div>
  );
}

export const Input = {
  Root: forwardRef(InputRoot),
  Prefix: InputPrefix,
  Suffix: InputSuffix,
  LeadingAddon: InputLeadingAddon,
  TrailingAddon: InputTrailingAddon,
};
