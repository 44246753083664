import type { AxiosInstance } from "axios";

const clearCacheQueryKey = "clear_cache";

export function setupCacheBusting(instances: AxiosInstance[]): void {
  const clearCache = new URLSearchParams(window.location.search).has(
    clearCacheQueryKey,
  );

  if (!clearCache) {
    return;
  }

  const url = new URL(window.location.href);
  url.searchParams.delete(clearCacheQueryKey);
  window.history.replaceState({}, "", url);

  for (const instance of instances) {
    instance.interceptors.request.use((config) => {
      config.params = config.params || {};
      config.params.clear_cache = true;
      return config;
    });
  }
}
