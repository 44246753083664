import type { SelectItem } from "./Select";

type FilteringStateOptions = {
  isSearchable?: boolean;
  selectedItems: SelectItem[];
  inputValue: string;
  multiple?: boolean;
};

export function isInFilteringState({
  isSearchable,
  selectedItems,
  inputValue,
}: FilteringStateOptions): boolean {
  if (!isSearchable) {
    return false;
  }
  if (!selectedItems.length) {
    return true;
  }
  return selectedItems[0]?.label !== inputValue;
}
