import type { JSX } from "react";

import {
  sva,
  cx,
  type RecipeVariantProps,
} from "@mobsuccess-devops/styled-system/css";
import { vstack } from "@mobsuccess-devops/styled-system/patterns";

import type { NativeProps } from "../../../types/native-props";

type LabelProps = {
  required?: boolean;
  subLabel?: string;
  optional?: boolean;
} & NativeProps<"label"> &
  RecipeVariantProps<typeof styles>;

export function Label({
  className,
  children,
  required,
  subLabel,
  optional,
  direction,
  ...rest
}: LabelProps): JSX.Element {
  const classes = styles({ required, direction });
  return (
    <label className={cx(classes.root, className)} {...rest}>
      <div className={classes.content}>{children}</div>
      {subLabel ? (
        <span className={cx(classes.subLabel, optional && classes.optional)}>
          {subLabel}
        </span>
      ) : null}
    </label>
  );
}

const styles = sva({
  slots: ["root", "container", "content", "subLabel", "optional"],
  base: {
    root: vstack.raw({ alignItems: "start", gap: "xs" }),
    content: {
      textStyle: "body",
      color: "interface",
    },
    subLabel: {
      textStyle: "muted",
      color: "interface.400",
    },
    optional: {
      textStyle: "muted",
      fontSize: "xs",
      color: "interface.300",
    },
  },
  variants: {
    direction: {
      horizontal: {
        root: {
          flexDirection: "row",
          alignItems: "baseline",
        },
      },
      vertical: {
        root: { flexDirection: "column" },
      },
    },
    required: {
      true: {
        content: {
          _after: {
            content: "' *'",
            color: "danger",
          },
        },
      },
    },
  },
  defaultVariants: {
    direction: "vertical",
  },
});
