import { StrictMode, type JSX } from "react";

import { i18n } from "@lingui/core";
import { AtomStoreProvider } from "@mobsuccess-devops/react-atom";
import { ThemeProvider } from "@mobsuccess-devops/react-ui/Theme";
import { Toaster } from "@mobsuccess-devops/react-ui/Toaster";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { createRoot } from "react-dom/client";
import AppRouter from "vite-config/router";

import { AuthProvider } from "../components/Auth";
import { AxiosContextProvider } from "../components/AxiosContextProvider/AxiosContextProvider";
import { EnsureUser } from "../components/EnsureUser/EnsureUser";
import FlashMessages from "../components/FlashMessages";
import { configure } from "../public/auth/auth";
import { Localized } from "../public/i18n/Localized";
import { activate, buildLocales, type LocaleModule } from "../public/i18n/i18n";
import type { BusinessUnits } from "../types/enums";

import { AxiosInstances, type AxiosInstancesConfig } from "./axios-instances";
import { getClient } from "./business-unit";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

type CreateRootOptions = {
  businessUnit: BusinessUnits;
  entrypoint: string;
  lingui: {
    defaultLanguage: string;
    locales: Record<string, LocaleModule>;
  };
  auth?: {
    userPoolsId: string;
    googleSignupUrl: URL;
    forgotPasswordUrl: URL;
    googleClientId: string;
    userPoolsWebClientId: string;
  };
  atoms?: Record<string, unknown> | true;
  axios?: AxiosInstancesConfig;
};

export async function createAppRoot({
  auth,
  atoms,
  axios,
  lingui,
  entrypoint,
  businessUnit,
}: CreateRootOptions): Promise<void> {
  const root = window?.top?.document.getElementById(
    `react_root--${entrypoint}`,
  );
  if (!root) {
    throw new Error(`Could not find root element for ${entrypoint}`);
  }

  const locales = buildLocales(lingui.locales);
  await Promise.all([configure(auth), activate(i18n, locales)]);

  const client = getClient();

  const axiosInstances = axios ? new AxiosInstances(axios) : undefined;

  const AxiosWrapper: React.FC<{ children: JSX.Element }> = ({ children }) => {
    if (!axiosInstances) {
      return <>{children}</>;
    }
    return (
      <AxiosContextProvider orchestrator={axiosInstances}>
        {children}
      </AxiosContextProvider>
    );
  };

  const AuthWrapper: React.FC<{ children: JSX.Element }> = ({ children }) => {
    if (auth) {
      return (
        <AuthProvider
          businessUnit={businessUnit}
          googleClientId={auth.googleClientId}
          googleSignupUrl={auth.googleSignupUrl}
          forgotPasswordUrl={auth.forgotPasswordUrl}
          onBearerRefresh={axiosInstances?.authorize} // this will setup authorization for all axios instances
          userPoolsWebClientId={auth.userPoolsWebClientId}
        >
          <EnsureUser>{children}</EnsureUser>
        </AuthProvider>
      );
    }
    return <>{children}</>;
  };

  const AtomWrapper: React.FC<{ children: JSX.Element }> = ({ children }) => {
    if (atoms) {
      const store = atoms === true ? undefined : atoms;
      return (
        <AtomStoreProvider initialStore={store}>{children}</AtomStoreProvider>
      );
    }
    return <>{children}</>;
  };

  return createRoot(root).render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom" />
        <ThemeProvider client={client}>
          <Toaster>
            <Localized i18n={i18n} locales={locales}>
              <FlashMessages>
                <AuthWrapper>
                  <AxiosWrapper>
                    <AtomWrapper>
                      <AppRouter />
                    </AtomWrapper>
                  </AxiosWrapper>
                </AuthWrapper>
              </FlashMessages>
            </Localized>
          </Toaster>
        </ThemeProvider>
      </QueryClientProvider>
    </StrictMode>,
  );
}
