import { useEffect, useRef, useState } from "react";

import { MSAxiosInstance as DashboardDataMSAxiosInstance } from "@mobsuccess-devops/dashboard-data-react-client";
import { getEndpointServiceBranch } from "@mobsuccess-devops/rdc-react-client";

import type { AxiosInstance } from "axios";

import { setupCacheBusting } from "../../public/fetch";

type Interceptor<T extends "request" | "response"> = {
  type: T;
  name: string;
  instances: AxiosInstance[];
  params: { current: Parameters<AxiosInstance["interceptors"][T]["use"]> };
};

type InstanceInfo = {
  base: string;
  instance: AxiosInstance;
  setupDashboardData?: boolean;
};

export type AxiosInstancesConfig =
  | Record<string, InstanceInfo>
  | [Record<string, InstanceInfo>, { cacheBusting?: boolean }];

export class AxiosInstances {
  private requests: Array<Interceptor<"request">> = [];
  private responses: Array<Interceptor<"response">> = [];
  public instances: Record<string, InstanceInfo>;

  constructor(config: AxiosInstancesConfig) {
    const [instances, { cacheBusting }] = Array.isArray(config)
      ? config
      : [config, {}];
    this.instances = instances;
    for (const { instance, base, setupDashboardData } of Object.values(
      instances,
    )) {
      if (setupDashboardData) {
        DashboardDataMSAxiosInstance.defaults.baseURL = base;
      }
      instance.defaults.baseURL = base;
    }
    if (cacheBusting) {
      setupCacheBusting(
        Object.values(instances).map(({ instance }) => instance),
      );
    }
    this.authorize = this.authorize.bind(this);
  }

  authorize(bearer: string): void {
    DashboardDataMSAxiosInstance.defaults.headers.common.Authorization = bearer;
    for (const { instance } of Object.values(this.instances)) {
      instance.defaults.headers.common.Authorization = bearer;
    }
  }

  loadUrlOverrides(bearer: string | null): () => Promise<null> {
    if (!import.meta.env.VITE_DATA_CONTEXT_SERVICE_RDC) {
      global.console.info(
        "[react-shared]: Could not load url overrides as rdc-microservice base url is missing",
      );
      return () => Promise.resolve(null);
    }
    if (!bearer) {
      global.console.info(
        "[react-shared]: Could not load url overrides as bearer is missing",
      );
      return () => Promise.resolve(null);
    }
    return async () => {
      for await (const [service, { instance }] of Object.entries(
        this.instances,
      )) {
        const override = new URLSearchParams(window.location.search).get(
          `${service}-microservice`,
        );
        try {
          if (!override) {
            continue;
          }
          const { body } = await getEndpointServiceBranch(
            `${service}-microservice`,
            override,
            {
              headers: {
                Authorization: bearer,
              },
            },
          );
          if (body.status !== "ready") {
            global.console.warn(
              `[react-shared]: service ${service} (${override}) is not ready`,
            );
            continue;
          }
          instance.defaults.baseURL = body.url;
        } catch {
          global.console.error(
            `[react-shared]: Could not load url overrides for ${service} (${override})`,
          );
        }
      }
      return null;
    };
  }

  useRequestInterceptor(
    name: string,
    ...params: Parameters<AxiosInstance["interceptors"]["request"]["use"]>
  ): boolean {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [registered, setRegistered] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const ref = useRef(params);
    ref.current = params;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (this.requests.some((interceptor) => interceptor.name === name)) {
        throw new Error(`Interceptor with name ${name} already exists`);
      }
      const interceptor: Interceptor<"request"> = {
        type: "request",
        name,
        instances: [],
        params: ref,
      };
      this.requests.push(interceptor);
      const cleanups: Array<() => void> = [];
      for (const { instance } of Object.values(this.instances)) {
        const interceptorId = instance.interceptors.request.use(
          (config) => {
            const [onFulfilled] = ref.current;
            if (!onFulfilled) {
              return config;
            }
            return onFulfilled(config);
          },
          (error) => {
            const [, onRejected] = ref.current;
            if (!onRejected) {
              return Promise.reject(error);
            }
            return onRejected(error);
          },
        );
        interceptor.instances.push(instance);
        cleanups.push(() => {
          instance.interceptors.request.eject(interceptorId);
        });
      }
      setRegistered(true);
      return () => {
        setRegistered(false);
        this.requests = this.requests.filter(
          (interceptor) => interceptor.name !== name,
        );
        for (const cleanup of cleanups) {
          cleanup();
        }
      };
    }, [name]);
    return registered;
  }

  useResponseInterceptor(
    name: string,
    ...params: Parameters<AxiosInstance["interceptors"]["response"]["use"]>
  ): boolean {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [registered, setRegistered] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const ref = useRef(params);
    ref.current = params;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (this.responses.some((interceptor) => interceptor.name === name)) {
        throw new Error(`Interceptor with name ${name} already exists`);
      }
      const interceptor: Interceptor<"response"> = {
        type: "response",
        name,
        instances: [],
        params: ref,
      };
      this.responses.push(interceptor);
      const cleanups: Array<() => void> = [];
      for (const { instance } of Object.values(this.instances)) {
        const interceptorId = instance.interceptors.response.use(
          (response) => {
            const [onFulfilled] = ref.current;
            if (!onFulfilled) {
              return response;
            }
            return onFulfilled(response);
          },
          (error) => {
            const [, onRejected] = ref.current;
            if (!onRejected) {
              return Promise.reject(error);
            }
            return onRejected(error);
          },
        );
        interceptor.instances.push(instance);
        cleanups.push(() => {
          instance.interceptors.response.eject(interceptorId);
        });
      }
      setRegistered(true);
      return () => {
        setRegistered(false);
        this.responses = this.responses.filter(
          (interceptor) => interceptor.name !== name,
        );
        for (const cleanup of cleanups) {
          cleanup();
        }
      };
    }, [name]);
    return registered;
  }
}
