const tokens = {
  "colors.mobsuccess.primary.100": {
    "value": "#F8F4FA",
    "variable": "var(--colors-mobsuccess-primary-100)"
  },
  "colors.mobsuccess.primary.200": {
    "value": "#F1EAF6",
    "variable": "var(--colors-mobsuccess-primary-200)"
  },
  "colors.mobsuccess.primary.400": {
    "value": "#9231C9",
    "variable": "var(--colors-mobsuccess-primary-400)"
  },
  "colors.mobsuccess.primary.500": {
    "value": "#6C1F98",
    "variable": "var(--colors-mobsuccess-primary-500)"
  },
  "colors.mobsuccess.primary.600": {
    "value": "#551778",
    "variable": "var(--colors-mobsuccess-primary-600)"
  },
  "colors.mobsuccess.secondary.100": {
    "value": "#FDF8F7",
    "variable": "var(--colors-mobsuccess-secondary-100)"
  },
  "colors.mobsuccess.secondary.200": {
    "value": "#FBF0EF",
    "variable": "var(--colors-mobsuccess-secondary-200)"
  },
  "colors.mobsuccess.secondary.400": {
    "value": "#FC9882",
    "variable": "var(--colors-mobsuccess-secondary-400)"
  },
  "colors.mobsuccess.secondary.500": {
    "value": "#F66A4C",
    "variable": "var(--colors-mobsuccess-secondary-500)"
  },
  "colors.mobsuccess.secondary.600": {
    "value": "#EE411B",
    "variable": "var(--colors-mobsuccess-secondary-600)"
  },
  "colors.leboncoin.primary.100": {
    "value": "#FEF1E9",
    "variable": "var(--colors-leboncoin-primary-100)"
  },
  "colors.leboncoin.primary.200": {
    "value": "#FFE0D4",
    "variable": "var(--colors-leboncoin-primary-200)"
  },
  "colors.leboncoin.primary.400": {
    "value": "#FFA37D",
    "variable": "var(--colors-leboncoin-primary-400)"
  },
  "colors.leboncoin.primary.500": {
    "value": "#FF6626",
    "variable": "var(--colors-leboncoin-primary-500)"
  },
  "colors.leboncoin.primary.600": {
    "value": "#E65C22",
    "variable": "var(--colors-leboncoin-primary-600)"
  },
  "colors.feuvert.primary.100": {
    "value": "#F5FFFF",
    "variable": "var(--colors-feuvert-primary-100)"
  },
  "colors.feuvert.primary.200": {
    "value": "#B8E4BA",
    "variable": "var(--colors-feuvert-primary-200)"
  },
  "colors.feuvert.primary.400": {
    "value": "#7ACD7F",
    "variable": "var(--colors-feuvert-primary-400)"
  },
  "colors.feuvert.primary.500": {
    "value": "#4DA952",
    "variable": "var(--colors-feuvert-primary-500)"
  },
  "colors.feuvert.primary.600": {
    "value": "#009E09",
    "variable": "var(--colors-feuvert-primary-600)"
  },
  "colors.decathlon.primary.100": {
    "value": "#E7F3F9",
    "variable": "var(--colors-decathlon-primary-100)"
  },
  "colors.decathlon.primary.200": {
    "value": "#BEDEEF",
    "variable": "var(--colors-decathlon-primary-200)"
  },
  "colors.decathlon.primary.400": {
    "value": "#3D9ACC",
    "variable": "var(--colors-decathlon-primary-400)"
  },
  "colors.decathlon.primary.500": {
    "value": "#007DBC",
    "variable": "var(--colors-decathlon-primary-500)"
  },
  "colors.decathlon.primary.600": {
    "value": "#00689D",
    "variable": "var(--colors-decathlon-primary-600)"
  },
  "colors.leroymerlin.primary.100": {
    "value": "#EBF5DE",
    "variable": "var(--colors-leroymerlin-primary-100)"
  },
  "colors.leroymerlin.primary.200": {
    "value": "#C5E39E",
    "variable": "var(--colors-leroymerlin-primary-200)"
  },
  "colors.leroymerlin.primary.400": {
    "value": "#46A610",
    "variable": "var(--colors-leroymerlin-primary-400)"
  },
  "colors.leroymerlin.primary.500": {
    "value": "#188803",
    "variable": "var(--colors-leroymerlin-primary-500)"
  },
  "colors.leroymerlin.primary.600": {
    "value": "#006902",
    "variable": "var(--colors-leroymerlin-primary-600)"
  },
  "colors.common.interface.100": {
    "value": "#F8F9FB",
    "variable": "var(--colors-common-interface-100)"
  },
  "colors.common.interface.200": {
    "value": "#F1F2F8",
    "variable": "var(--colors-common-interface-200)"
  },
  "colors.common.interface.300": {
    "value": "#BCC1DE",
    "variable": "var(--colors-common-interface-300)"
  },
  "colors.common.interface.400": {
    "value": "#606DAF",
    "variable": "var(--colors-common-interface-400)"
  },
  "colors.common.interface.500": {
    "value": "#4F5B92",
    "variable": "var(--colors-common-interface-500)"
  },
  "colors.common.interface.600": {
    "value": "#2E3661",
    "variable": "var(--colors-common-interface-600)"
  },
  "colors.gray.100": {
    "value": "#F3F4F6",
    "variable": "var(--colors-gray-100)"
  },
  "colors.gray.200": {
    "value": "#E8EAEC",
    "variable": "var(--colors-gray-200)"
  },
  "colors.gray.300": {
    "value": "#DCDFE3",
    "variable": "var(--colors-gray-300)"
  },
  "colors.gray.400": {
    "value": "#BAC0C7",
    "variable": "var(--colors-gray-400)"
  },
  "colors.gray.500": {
    "value": "#A3ABB5",
    "variable": "var(--colors-gray-500)"
  },
  "colors.gray.600": {
    "value": "#8C96A2",
    "variable": "var(--colors-gray-600)"
  },
  "colors.gray.700": {
    "value": "#717982",
    "variable": "var(--colors-gray-700)"
  },
  "colors.gray.800": {
    "value": "#555B62",
    "variable": "var(--colors-gray-800)"
  },
  "colors.gray.900": {
    "value": "#3A3E43",
    "variable": "var(--colors-gray-900)"
  },
  "colors.info.100": {
    "value": "#F1FAFE",
    "variable": "var(--colors-info-100)"
  },
  "colors.info.200": {
    "value": "#E3F4FD",
    "variable": "var(--colors-info-200)"
  },
  "colors.info.400": {
    "value": "#8FCDF0",
    "variable": "var(--colors-info-400)"
  },
  "colors.info.500": {
    "value": "#7BC2EA",
    "variable": "var(--colors-info-500)"
  },
  "colors.info.600": {
    "value": "#4FAEE3",
    "variable": "var(--colors-info-600)"
  },
  "colors.success.100": {
    "value": "#ECFEFC",
    "variable": "var(--colors-success-100)"
  },
  "colors.success.200": {
    "value": "#D4FCF8",
    "variable": "var(--colors-success-200)"
  },
  "colors.success.400": {
    "value": "#02D7BE",
    "variable": "var(--colors-success-400)"
  },
  "colors.success.500": {
    "value": "#00B8A2",
    "variable": "var(--colors-success-500)"
  },
  "colors.success.600": {
    "value": "#008F7E",
    "variable": "var(--colors-success-600)"
  },
  "colors.warning.100": {
    "value": "#FFFCEA",
    "variable": "var(--colors-warning-100)"
  },
  "colors.warning.200": {
    "value": "#FFEEDB",
    "variable": "var(--colors-warning-200)"
  },
  "colors.warning.400": {
    "value": "#FFBA6B",
    "variable": "var(--colors-warning-400)"
  },
  "colors.warning.500": {
    "value": "#FDA63F",
    "variable": "var(--colors-warning-500)"
  },
  "colors.warning.600": {
    "value": "#FC8E0D",
    "variable": "var(--colors-warning-600)"
  },
  "colors.danger.100": {
    "value": "#FFF5F5",
    "variable": "var(--colors-danger-100)"
  },
  "colors.danger.200": {
    "value": "#FEEBEB",
    "variable": "var(--colors-danger-200)"
  },
  "colors.danger.400": {
    "value": "#FE8B8B",
    "variable": "var(--colors-danger-400)"
  },
  "colors.danger.500": {
    "value": "#FE7272",
    "variable": "var(--colors-danger-500)"
  },
  "colors.danger.600": {
    "value": "#FA4242",
    "variable": "var(--colors-danger-600)"
  },
  "colors.neutral.100": {
    "value": "#152133",
    "variable": "var(--colors-neutral-100)"
  },
  "colors.neutral.200": {
    "value": "#8C96A2",
    "variable": "var(--colors-neutral-200)"
  },
  "colors.neutral.300": {
    "value": "#F2F5F6",
    "variable": "var(--colors-neutral-300)"
  },
  "sizes.xs": {
    "value": "12px",
    "variable": "var(--sizes-xs)"
  },
  "sizes.sm": {
    "value": "16px",
    "variable": "var(--sizes-sm)"
  },
  "sizes.md": {
    "value": "24px",
    "variable": "var(--sizes-md)"
  },
  "sizes.lg": {
    "value": "32px",
    "variable": "var(--sizes-lg)"
  },
  "sizes.xl": {
    "value": "48px",
    "variable": "var(--sizes-xl)"
  },
  "sizes.breakpoint-laptop": {
    "value": "1024px",
    "variable": "var(--sizes-breakpoint-laptop)"
  },
  "sizes.breakpoint-widescreen": {
    "value": "1650px",
    "variable": "var(--sizes-breakpoint-widescreen)"
  },
  "spacing.xs": {
    "value": "4px",
    "variable": "var(--spacing-xs)"
  },
  "spacing.sm": {
    "value": "8px",
    "variable": "var(--spacing-sm)"
  },
  "spacing.md": {
    "value": "16px",
    "variable": "var(--spacing-md)"
  },
  "spacing.lg": {
    "value": "24px",
    "variable": "var(--spacing-lg)"
  },
  "spacing.xl": {
    "value": "32px",
    "variable": "var(--spacing-xl)"
  },
  "fontSizes.xs": {
    "value": "10px",
    "variable": "var(--font-sizes-xs)"
  },
  "fontSizes.sm": {
    "value": "12px",
    "variable": "var(--font-sizes-sm)"
  },
  "fontSizes.md": {
    "value": "14px",
    "variable": "var(--font-sizes-md)"
  },
  "fontSizes.lg": {
    "value": "16px",
    "variable": "var(--font-sizes-lg)"
  },
  "fontSizes.xl": {
    "value": "20px",
    "variable": "var(--font-sizes-xl)"
  },
  "lineHeights.xs": {
    "value": "14px",
    "variable": "var(--line-heights-xs)"
  },
  "lineHeights.sm": {
    "value": "16px",
    "variable": "var(--line-heights-sm)"
  },
  "lineHeights.md": {
    "value": "20px",
    "variable": "var(--line-heights-md)"
  },
  "lineHeights.lg": {
    "value": "24px",
    "variable": "var(--line-heights-lg)"
  },
  "lineHeights.xl": {
    "value": "32px",
    "variable": "var(--line-heights-xl)"
  },
  "radii.sm": {
    "value": "4px",
    "variable": "var(--radii-sm)"
  },
  "radii.md": {
    "value": "8px",
    "variable": "var(--radii-md)"
  },
  "radii.lg": {
    "value": "12px",
    "variable": "var(--radii-lg)"
  },
  "radii.round": {
    "value": "999px",
    "variable": "var(--radii-round)"
  },
  "zIndex.default": {
    "value": 0,
    "variable": "var(--z-index-default)"
  },
  "zIndex.overlay": {
    "value": 100,
    "variable": "var(--z-index-overlay)"
  },
  "zIndex.popover": {
    "value": 1000,
    "variable": "var(--z-index-popover)"
  },
  "zIndex.toolbar": {
    "value": 10000,
    "variable": "var(--z-index-toolbar)"
  },
  "zIndex.sidebar": {
    "value": 100000,
    "variable": "var(--z-index-sidebar)"
  },
  "zIndex.modal": {
    "value": 1000000,
    "variable": "var(--z-index-modal)"
  },
  "zIndex.top": {
    "value": 10000000,
    "variable": "var(--z-index-top)"
  },
  "shadows.card-sm": {
    "value": "0px 0px 8px 0px #232B5214, 0px 4px 8px 0px #232B520A",
    "variable": "var(--shadows-card-sm)"
  },
  "shadows.card-xs": {
    "value": "0 4px 12px rgba(0,0,0,0.04)",
    "variable": "var(--shadows-card-xs)"
  },
  "breakpoints.laptop": {
    "value": "1024px",
    "variable": "var(--breakpoints-laptop)"
  },
  "breakpoints.widescreen": {
    "value": "1650px",
    "variable": "var(--breakpoints-widescreen)"
  },
  "colors.secondary.100": {
    "value": "var(--colors-mobsuccess-secondary-100)",
    "variable": "var(--colors-secondary-100)"
  },
  "colors.secondary.200": {
    "value": "var(--colors-mobsuccess-secondary-200)",
    "variable": "var(--colors-secondary-200)"
  },
  "colors.secondary.400": {
    "value": "var(--colors-mobsuccess-secondary-400)",
    "variable": "var(--colors-secondary-400)"
  },
  "colors.secondary.500": {
    "value": "var(--colors-mobsuccess-secondary-500)",
    "variable": "var(--colors-secondary-500)"
  },
  "colors.secondary.600": {
    "value": "var(--colors-mobsuccess-secondary-600)",
    "variable": "var(--colors-secondary-600)"
  },
  "colors.secondary": {
    "value": "var(--colors-mobsuccess-secondary-500)",
    "variable": "var(--colors-secondary)"
  },
  "colors.interface.100": {
    "value": "var(--colors-common-interface-100)",
    "variable": "var(--colors-interface-100)"
  },
  "colors.interface.200": {
    "value": "var(--colors-common-interface-200)",
    "variable": "var(--colors-interface-200)"
  },
  "colors.interface.300": {
    "value": "var(--colors-common-interface-300)",
    "variable": "var(--colors-interface-300)"
  },
  "colors.interface.400": {
    "value": "var(--colors-common-interface-400)",
    "variable": "var(--colors-interface-400)"
  },
  "colors.info": {
    "value": "var(--colors-info-500)",
    "variable": "var(--colors-info)"
  },
  "colors.success": {
    "value": "var(--colors-success-500)",
    "variable": "var(--colors-success)"
  },
  "colors.warning": {
    "value": "var(--colors-warning-500)",
    "variable": "var(--colors-warning)"
  },
  "colors.danger": {
    "value": "var(--colors-danger-500)",
    "variable": "var(--colors-danger)"
  },
  "colors.neutral": {
    "value": "var(--colors-neutral-100)",
    "variable": "var(--colors-neutral)"
  },
  "spacing.-xs": {
    "value": "calc(var(--spacing-xs) * -1)",
    "variable": "var(--spacing-xs)"
  },
  "spacing.-sm": {
    "value": "calc(var(--spacing-sm) * -1)",
    "variable": "var(--spacing-sm)"
  },
  "spacing.-md": {
    "value": "calc(var(--spacing-md) * -1)",
    "variable": "var(--spacing-md)"
  },
  "spacing.-lg": {
    "value": "calc(var(--spacing-lg) * -1)",
    "variable": "var(--spacing-lg)"
  },
  "spacing.-xl": {
    "value": "calc(var(--spacing-xl) * -1)",
    "variable": "var(--spacing-xl)"
  },
  "colors.primary.100": {
    "value": "var(--colors-primary-100)",
    "variable": "var(--colors-primary-100)"
  },
  "colors.primary.200": {
    "value": "var(--colors-primary-200)",
    "variable": "var(--colors-primary-200)"
  },
  "colors.primary.400": {
    "value": "var(--colors-primary-400)",
    "variable": "var(--colors-primary-400)"
  },
  "colors.primary.500": {
    "value": "var(--colors-primary-500)",
    "variable": "var(--colors-primary-500)"
  },
  "colors.primary.600": {
    "value": "var(--colors-primary-600)",
    "variable": "var(--colors-primary-600)"
  },
  "colors.primary": {
    "value": "var(--colors-primary)",
    "variable": "var(--colors-primary)"
  },
  "colors.interface.500": {
    "value": "var(--colors-interface-500)",
    "variable": "var(--colors-interface-500)"
  },
  "colors.interface.600": {
    "value": "var(--colors-interface-600)",
    "variable": "var(--colors-interface-600)"
  },
  "colors.interface": {
    "value": "var(--colors-interface)",
    "variable": "var(--colors-interface)"
  },
  "colors.colorPalette.primary.100": {
    "value": "var(--colors-color-palette-primary-100)",
    "variable": "var(--colors-color-palette-primary-100)"
  },
  "colors.colorPalette.100": {
    "value": "var(--colors-color-palette-100)",
    "variable": "var(--colors-color-palette-100)"
  },
  "colors.colorPalette.primary.200": {
    "value": "var(--colors-color-palette-primary-200)",
    "variable": "var(--colors-color-palette-primary-200)"
  },
  "colors.colorPalette.200": {
    "value": "var(--colors-color-palette-200)",
    "variable": "var(--colors-color-palette-200)"
  },
  "colors.colorPalette.primary.400": {
    "value": "var(--colors-color-palette-primary-400)",
    "variable": "var(--colors-color-palette-primary-400)"
  },
  "colors.colorPalette.400": {
    "value": "var(--colors-color-palette-400)",
    "variable": "var(--colors-color-palette-400)"
  },
  "colors.colorPalette.primary.500": {
    "value": "var(--colors-color-palette-primary-500)",
    "variable": "var(--colors-color-palette-primary-500)"
  },
  "colors.colorPalette.500": {
    "value": "var(--colors-color-palette-500)",
    "variable": "var(--colors-color-palette-500)"
  },
  "colors.colorPalette.primary.600": {
    "value": "var(--colors-color-palette-primary-600)",
    "variable": "var(--colors-color-palette-primary-600)"
  },
  "colors.colorPalette.600": {
    "value": "var(--colors-color-palette-600)",
    "variable": "var(--colors-color-palette-600)"
  },
  "colors.colorPalette.secondary.100": {
    "value": "var(--colors-color-palette-secondary-100)",
    "variable": "var(--colors-color-palette-secondary-100)"
  },
  "colors.colorPalette.secondary.200": {
    "value": "var(--colors-color-palette-secondary-200)",
    "variable": "var(--colors-color-palette-secondary-200)"
  },
  "colors.colorPalette.secondary.400": {
    "value": "var(--colors-color-palette-secondary-400)",
    "variable": "var(--colors-color-palette-secondary-400)"
  },
  "colors.colorPalette.secondary.500": {
    "value": "var(--colors-color-palette-secondary-500)",
    "variable": "var(--colors-color-palette-secondary-500)"
  },
  "colors.colorPalette.secondary.600": {
    "value": "var(--colors-color-palette-secondary-600)",
    "variable": "var(--colors-color-palette-secondary-600)"
  },
  "colors.colorPalette.interface.100": {
    "value": "var(--colors-color-palette-interface-100)",
    "variable": "var(--colors-color-palette-interface-100)"
  },
  "colors.colorPalette.interface.200": {
    "value": "var(--colors-color-palette-interface-200)",
    "variable": "var(--colors-color-palette-interface-200)"
  },
  "colors.colorPalette.interface.300": {
    "value": "var(--colors-color-palette-interface-300)",
    "variable": "var(--colors-color-palette-interface-300)"
  },
  "colors.colorPalette.300": {
    "value": "var(--colors-color-palette-300)",
    "variable": "var(--colors-color-palette-300)"
  },
  "colors.colorPalette.interface.400": {
    "value": "var(--colors-color-palette-interface-400)",
    "variable": "var(--colors-color-palette-interface-400)"
  },
  "colors.colorPalette.interface.500": {
    "value": "var(--colors-color-palette-interface-500)",
    "variable": "var(--colors-color-palette-interface-500)"
  },
  "colors.colorPalette.interface.600": {
    "value": "var(--colors-color-palette-interface-600)",
    "variable": "var(--colors-color-palette-interface-600)"
  },
  "colors.colorPalette.700": {
    "value": "var(--colors-color-palette-700)",
    "variable": "var(--colors-color-palette-700)"
  },
  "colors.colorPalette.800": {
    "value": "var(--colors-color-palette-800)",
    "variable": "var(--colors-color-palette-800)"
  },
  "colors.colorPalette.900": {
    "value": "var(--colors-color-palette-900)",
    "variable": "var(--colors-color-palette-900)"
  },
  "colors.colorPalette": {
    "value": "var(--colors-color-palette)",
    "variable": "var(--colors-color-palette)"
  }
}

export function token(path, fallback) {
  return tokens[path]?.value || fallback
}

function tokenVar(path, fallback) {
  return tokens[path]?.variable || fallback
}

token.var = tokenVar