import { sva } from "@mobsuccess-devops/styled-system/css";
import type { RecipeVariantProps } from "@mobsuccess-devops/styled-system/types";

export type InputVariants = RecipeVariantProps<typeof inputStyles>;

export const inputStyles = sva({
  slots: [
    "root",
    "inputWrapper",
    "input",
    "prefix",
    "suffix",
    "leftAddon",
    "rightAddon",
  ],
  base: {
    root: {
      backgroundColor: "white",
      display: "flex",
      overflow: "hidden",
      paddingX: "sm",
      gap: "8px",
      alignItems: "center",
      position: "relative",
      width: "100%",
      _disabled: {
        cursor: "not-allowed",
        opacity: "0.5",
      },
    },
    input: {
      textStyle: "body",
      fontSize: "inherit",
      position: "relative",
      cursor: "inherit",
      width: "100%",
      order: 2,
      border: "none",
      outline: "none",
      color: "interface.500",
      backgroundColor: "transparent",
      _placeholder: {
        color: "interface.300",
      },
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
      },
      "&[type=number]": {
        appearance: "textfield",
        /// @ts-expect-error ???
        MozAppearance: "textfield",
      },
      _hover: {
        '&[data-border-style="textBackground"]': {
          borderColor: "interface.100",
        },
      },
    },
  },
  variants: {
    size: {
      sm: {
        root: {
          height: "32px",
          fontSize: "12px",
          borderRadius: "4px",
        },
      },
      md: {
        root: {
          height: "40px",
          fontSize: "14px",
          borderRadius: "6px",
        },
        inputWrapper: {
          paddingY: "10px",
          paddingX: "12px",
        },
      },
      lg: {
        root: {
          height: "48px",
          fontSize: "lg",
          borderRadius: "8px",
        },
        inputWrapper: {
          paddingY: "12px",
          paddingX: "md",
        },
      },
      xl: {
        root: {
          height: "56px",
          fontSize: "20px",
          borderRadius: "10px",
        },
        inputWrapper: {
          paddingY: "12px",
          paddingX: "20px",
        },
      },
    },
    textPosition: {
      start: {
        input: {
          textAlign: "start",
        },
      },
      end: {
        input: {
          textAlign: "end",
        },
      },
      center: {
        input: {
          textAlign: "center",
        },
      },
    },
  },
  defaultVariants: {
    size: "sm",
    textPosition: "start",
  },
});
