/* eslint-disable @mobsuccess-devops/mobsuccess/react */
import type { JSX } from "react";

import {
  type UseComboboxContext,
  Combobox,
  useComboboxContext,
} from "@ark-ui/react";
import { IconChecked } from "@mobsuccess-devops/icons";
import { HStack, Wrap } from "@mobsuccess-devops/styled-system/jsx";

import { useLocales } from "../../../features/localization";
import { dropdownStyles } from "../../../features/shared-styles/dropdown";
import { Typo } from "../../Typo";
import { ZFactor } from "../../ZFactor";
import { Button } from "../Button/Button";

import {
  type SelectItem,
  type SelectRootProps,
  useIsInFilteringState,
} from "./Select";

type SelectItemsDropdownProps<T extends SelectItem> = Pick<
  SelectRootProps<T>,
  "filterFunction" | "isSearchable" | "width" | "items" | "multiple"
>;

export function SelectItemsDropdown<T extends SelectItem>({
  items,
  width,
  isSearchable,
  filterFunction,
  multiple,
}: SelectItemsDropdownProps<T>): JSX.Element | null {
  const context: UseComboboxContext<T> = useComboboxContext();
  const dropdownClasses = dropdownStyles();
  const isInFilteringState = useIsInFilteringState(context, isSearchable);
  const locales = useLocales();
  if (!context.open) {
    return null;
  }
  return (
    <ZFactor.Portal>
      <Combobox.Positioner>
        <Combobox.Content className={dropdownClasses.content} style={{ width }}>
          {multiple && (
            <Wrap padding="sm" gap="sm" position="sticky" top={0} bg="white">
              {context.value.length !== context.collection.items.length && (
                <Button
                  autoFocus={false}
                  size="sm"
                  palette="interface"
                  onClick={() => {
                    context.setValue(
                      context.collection.items.map((item) => item.value),
                    );
                  }}
                  data-tracking-id="bTm-wFJBqU"
                >
                  {locales.select.selectAll}
                </Button>
              )}
              <Combobox.ClearTrigger asChild>
                <Button
                  autoFocus={false}
                  size="sm"
                  palette="interface"
                  data-tracking-id="w6zemHOvhZ"
                >
                  {locales.select.removeAll}
                </Button>
              </Combobox.ClearTrigger>
            </Wrap>
          )}
          {items.map((item) =>
            item.type === "group" ? (
              <Combobox.ItemGroup
                id={item.id}
                key={item.id}
                className={dropdownClasses.group}
                hidden={
                  isInFilteringState &&
                  !filterFunction?.(item, context.inputValue)
                }
              >
                <div className={dropdownClasses.groupLabel}>{item.label}</div>
                {item.items.map((groupItem) => (
                  <Combobox.Item
                    item={groupItem}
                    hidden={
                      isInFilteringState &&
                      !filterFunction?.(groupItem, context.inputValue, item)
                    }
                    key={groupItem.value}
                    className={dropdownClasses.item}
                  >
                    <Combobox.ItemContext>
                      {(itemContext) => (
                        <>
                          <HStack>
                            {groupItem.decorators}
                            <Typo.Body order={2}>{groupItem.label}</Typo.Body>
                          </HStack>
                          {itemContext.selected && (
                            <IconChecked
                              color="primary.500"
                              size="sm"
                              ml="auto"
                            />
                          )}
                        </>
                      )}
                    </Combobox.ItemContext>
                  </Combobox.Item>
                ))}
              </Combobox.ItemGroup>
            ) : (
              <Combobox.Item
                item={item}
                key={item.value}
                className={dropdownClasses.item}
                hidden={
                  isInFilteringState &&
                  !filterFunction?.(item, context.inputValue)
                }
              >
                <Combobox.ItemContext>
                  {(itemContext) => (
                    <>
                      <HStack>
                        {item.decorators}
                        <Typo.Body order={2}>{item.label}</Typo.Body>
                      </HStack>
                      {itemContext.selected && (
                        <IconChecked ml="auto" color="primary.500" size="sm" />
                      )}
                    </>
                  )}
                </Combobox.ItemContext>
              </Combobox.Item>
            ),
          )}
        </Combobox.Content>
      </Combobox.Positioner>
    </ZFactor.Portal>
  );
}
