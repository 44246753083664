import {
  type FormEventHandler,
  memo,
  useCallback,
  useState,
  type JSX,
} from "react";

import { t, Trans } from "@lingui/macro";
import { Container } from "@mobsuccess-devops/react-ui/Container";
import { FieldLayout, FieldDecorators } from "@mobsuccess-devops/react-ui/Form";
import { Separator } from "@mobsuccess-devops/react-ui/Separator";
import { Typo } from "@mobsuccess-devops/react-ui/Typo";
import { Typography } from "@mobsuccess-devops/react-ui/Typography";
import { Button, Input } from "@mobsuccess-devops/react-ui/_PandaArk";
import { css } from "@mobsuccess-devops/styled-system/css";
import { HStack } from "@mobsuccess-devops/styled-system/jsx";

import styled, { css as styledCss, useTheme } from "styled-components";

import { useSessionStorage } from "../../../features/storage";
import { AuthActionEnum, useAuth } from "../../../public/auth/auth";

import GoogleSignIn from "./GoogleSignIn";
import { PatSignIn } from "./PatSignIn/PatSignIn";

const FormUI = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 24px;
`;

const SeparatorUI = styled(Separator)`
  flex: unset;
`;

const paswordLostCss = styledCss`
  text-decoration: underline;
  cursor: pointer;
`;

enum LoginError {
  None = "none",
  Empty = "empty",
}

export type LoginProps = {
  isLoading?: boolean;
  googleClientId?: string;
  onChangeAuthAction: (action: AuthActionEnum, state?: object) => void;
};

function Login({
  googleClientId,
  onChangeAuthAction,
}: LoginProps): JSX.Element {
  const [form, setForm] = useState<HTMLFormElement | null>(null);

  const [autoLoginGoogle] = useSessionStorage("autoLoginGoogle", {
    defaultValue: true,
  });
  const { signIn } = useAuth();

  const [errors, setErrors] = useState({
    email: LoginError.None,
    password: LoginError.None,
  });
  const { palette } = useTheme();

  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      e.preventDefault();
      const email = e.currentTarget.email.value;
      const password = e.currentTarget.password.value;

      if (!email || !password) {
        setErrors({
          email: email ? LoginError.None : LoginError.Empty,
          password: password ? LoginError.None : LoginError.Empty,
        });
        return;
      }

      await signIn(email, password);
    },
    [signIn],
  );

  const handleClickForgotPassword = useCallback(() => {
    onChangeAuthAction(AuthActionEnum.ForgotPassword, {
      email: form?.email.value,
    });
  }, [form, onChangeAuthAction]);

  const renderError = useCallback((error: LoginError) => {
    switch (error) {
      case LoginError.Empty:
        return [
          <FieldDecorators.Feedback key="feedback" position="end" state="error">
            <Trans>#Auth.Login.error.empty</Trans>
          </FieldDecorators.Feedback>,
        ];
      default:
        return [];
    }
  }, []);

  const orUI = (
    <HStack width="100%" alignItems="center" justifyContent="center" gap="sm">
      <SeparatorUI color={palette.interface.darker[300]} />
      <Typo.Body color="interface.600" transform="uppercase">
        <Trans>#Auth.Login.or</Trans>
      </Typo.Body>
      <SeparatorUI color={palette.interface.darker[300]} />
    </HStack>
  );

  return (
    <FormUI id="auth-form" ref={setForm} onSubmit={handleSubmit}>
      <FieldLayout
        name="email"
        label={t`#Auth.Login.emailLabel`}
        required
        className={css({
          width: "100%",
        })}
        decorators={renderError(errors.email)}
      >
        <Input.Root
          required
          size="md"
          name="email"
          type="email"
          autoComplete="email"
          placeholder={t`#Auth.Login.emailPlaceholder`}
          state={errors.email === LoginError.Empty ? "error" : undefined}
        />
      </FieldLayout>
      <FieldLayout
        name="password"
        label={t`#Auth.Login.passwordLabel`}
        required
        className={css({
          width: "100%",
        })}
        decorators={renderError(errors.password)}
      >
        <Input.Root
          required
          size="md"
          name="password"
          type="password"
          autoComplete="current-password"
          placeholder={t`#Auth.Login.passwordPlaceholder`}
          state={errors.password === LoginError.Empty ? "error" : undefined}
        />
      </FieldLayout>
      <Container
        flexDirection="column"
        gap="24px"
        width="100%"
        alignItems="center"
      >
        <Button
          variant="dark"
          width="full"
          justifyContent="center"
          size="lg"
          formNoValidate
          form="auth-form"
          type="submit"
          data-tracking-id="login"
        >
          <Trans>#Auth.Login.login</Trans>
        </Button>
        <Typography
          as="span"
          styled={paswordLostCss}
          color={palette.primary.base}
          onClick={handleClickForgotPassword}
        >
          <Trans>#Auth.Login.forgottonPassword</Trans>
        </Typography>
      </Container>
      {import.meta.env.VITE_IS_DEV === "true" && (
        <>
          {orUI}
          <PatSignIn />
        </>
      )}
      {googleClientId && (
        <>
          {orUI}
          <GoogleSignIn
            autoSelect={autoLoginGoogle}
            googleClientId={googleClientId}
          />
        </>
      )}
    </FormUI>
  );
}

export default memo(Login);
