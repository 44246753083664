import type { JSX } from "react";

import { Combobox } from "@ark-ui/react";
import { IconArrowDouble, IconClose } from "@mobsuccess-devops/icons";
import { css, cx } from "@mobsuccess-devops/styled-system/css";
import { HStack } from "@mobsuccess-devops/styled-system/jsx";

import { composition } from "../../../features/utils/composition";
import { Input } from "../Input/Input";

import {
  SelectIcon,
  SelectTrigger,
  SelectAddonTrigger,
  defaultFilter,
} from "./Decorators";
import type { BaseSelectProps, SelectItem } from "./Select";
import { SelectItemsDropdown } from "./SelectItemsDropdown";
import { styles } from "./styles";

type SelectSingleProps<T extends SelectItem> = Omit<
  BaseSelectProps<T>,
  "multiple"
> & {
  // TODO: FTTECH-79 - Make type for input state
  state?: "error" | "success" | "warning";
};

export function SelectSingle<T extends SelectItem>({
  items,
  collection,
  isSearchable = true,
  width,
  isClearable = true,
  filterFunction = defaultFilter,
  children,
  size,
  state,
  ...props
}: SelectSingleProps<T>): JSX.Element {
  const decorators = composition.split(children, {
    icon: composition.single(SelectIcon),
    trigger: composition.single([SelectTrigger, SelectAddonTrigger]),
  });

  const classes = styles({
    addon: decorators.trigger && decorators.trigger.type === SelectAddonTrigger,
    size,
  });

  return (
    <Combobox.Root
      collection={collection}
      closeOnSelect={true}
      className={cx(classes.root, props.className)}
      data-validity-status={state}
      {...props}
    >
      <Combobox.Context<T>>
        {(context) => (
          <>
            {decorators.trigger ? (
              decorators.trigger
            ) : (
              <Combobox.Control
                // @ts-expect-error -- button onclick applied to div
                onClick={
                  context.open ? undefined : context.getTriggerProps().onClick
                }
              >
                <Combobox.Input asChild>
                  <Input.Root
                    size={size}
                    defaultValue={context.selectedItems?.[0]?.label ?? ""}
                    readOnly={!isSearchable}
                    placeholder={props.placeholder}
                    state={state}
                    data-validity-status={state}
                  >
                    {decorators.icon ||
                      (context.selectedItems?.[0]?.decorators && (
                        <Input.Prefix className={css({ center: true })}>
                          {decorators.icon ??
                            context.selectedItems?.[0]?.decorators}
                        </Input.Prefix>
                      ))}
                    <Input.Suffix>
                      <HStack gap="xs">
                        {isClearable && (
                          <Combobox.ClearTrigger
                            hidden={!context.selectedItems.length}
                            asChild
                          >
                            <button
                              className={cx(
                                css({
                                  center: true,
                                  h: "full",
                                  px: "sm",
                                }),
                                classes.clearTrigger,
                              )}
                              data-tracking-id="aVjfXuNue-"
                            >
                              <IconClose
                                variant="bold"
                                size="8px"
                                color="danger"
                              />
                            </button>
                          </Combobox.ClearTrigger>
                        )}
                        <IconArrowDouble size="8px" color="interface" />
                      </HStack>
                    </Input.Suffix>
                  </Input.Root>
                </Combobox.Input>
              </Combobox.Control>
            )}
            <SelectItemsDropdown
              items={items}
              width={width}
              isSearchable={isSearchable}
              filterFunction={filterFunction}
            />
          </>
        )}
      </Combobox.Context>
    </Combobox.Root>
  );
}
