import { createContext, type ContextType, useContext } from "react";

export const LocalesContext = createContext({
  locale: "en-US",
  general: {
    save: "Save",
    reset: "Reset",
  },
  select: {
    selectAll: "Select all",
    removeAll: "Remove all",
  },
  accountDropdown: {
    searchPlaceholder: "Search...",
  },
  headerAccountPicker: {
    searchPlaceholder: "Search...",
  },
  pagination: {
    pageSizeLabel: "Display",
    pageSizeOptions: (size: number) => `${size} lines`,
  },
  colorPicker: {
    savedSectionLabel: "Saved colors",
  },
  filters: {
    searchPlaceholder: "Search",
  },
  table: {
    arrangeColumns: "Arrange your columns",
  },
  sidebar: {
    termsAndConditions: "Terms and conditions",
    languageSelectorDialog: {
      title: "Change language",
      description: "Select your language",
      cancel: "Cancel",
      confirm: "Confirm",
    },
  },
  dateRangePicker: {
    reset: "Reset",
    from: "From",
    to: "to",
    rangePlaceholder: "Filter by date",
    thisWeek: "This week",
    lastWeek: "Last week",
    thisMonth: "This month",
    lastMonth: "Last month",
    thisQuarter: "This quarter",
    lastQuarter: "Last quarter",
    thisYear: "This year",
    lastYear: "Last year",
    last3Days: "Last 3 days",
    last7Days: "Last 7 days",
    last14Days: "Last 14 days",
    last30Days: "Last 30 days",
    last90Days: "Last 90 days",
    today: "Today",
    yesterday: "Yesterday",
  },
});

export function useLocales(): ContextType<typeof LocalesContext> {
  return useContext(LocalesContext);
}
